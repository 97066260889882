import React from "react"
import { Link } from "gatsby"

import { withStore } from "./withStore"
import {
  Wrapper,
  DetailsWrapper,
  DetailsWrapperInner,
  MapWrapper,
  Back,
  Divider,
  SpecialDescription,
  Title,
  SubTitle,
  DetailWrapper,
  Address,
  Text,
} from "./StoreStyles"
import { Map } from "../Map/Map"
import { Icon } from "../Icon/Icon"
import { SpecialOpenHours } from "../SpecialOpenHours/SpecialOpenHours"
import { Sections } from "../Sections/Sections"

export const Store = withStore(
  ({
    title,
    address,
    phone,
    email,
    location,
    openhours,
    specialTradingHours,
    specialDescription,
    store,
    backUrl,
    footerContent,
    additionalAddressLabelText,
    additionalPhoneLabelText,
    additionalEmailLabelText,
    additionalOpeningHoursLabelText,
    additionalBackToStoresButtonText,
  }) => (
    <>
      <Wrapper>
        <DetailsWrapper>
          <DetailsWrapperInner>
            <Back layout="desktop" as={Link} to={backUrl}>
              <Icon name={"arrowLeftSmall"} />
              <span>&nbsp;{additionalBackToStoresButtonText}</span>
            </Back>
            <Divider />
            {specialDescription?.length > 0 && (
              <SpecialDescription>{specialDescription}</SpecialDescription>
            )}
            <Title>{title}</Title>
            {address?.length > 0 && (
              <DetailWrapper>
                <SubTitle>{additionalAddressLabelText}</SubTitle>
                <Address>{address}</Address>
              </DetailWrapper>
            )}
            {phone?.length > 0 && (
              <DetailWrapper>
                <SubTitle>{additionalPhoneLabelText}</SubTitle>
                <Text as={"a"} href={`tel:${phone}`}>
                  {phone}
                </Text>
              </DetailWrapper>
            )}
            {email?.length > 0 && (
              <DetailWrapper>
                <SubTitle>{additionalEmailLabelText}</SubTitle>
                <Text
                  as={"a"}
                  target="_blank"
                  href={`mailto:${email}`}
                  rel="noopener noreferrer"
                >
                  {email}
                </Text>
              </DetailWrapper>
            )}
            {openhours?.length > 0 && (
              <DetailWrapper>
                <SubTitle>{additionalOpeningHoursLabelText}</SubTitle>
                {openhours?.map(item => (
                  <div key={item.day}>
                    <Text>{`${item.day}:`}</Text>
                    &nbsp;
                    <Text>{item.hours}</Text>
                  </div>
                ))}
              </DetailWrapper>
            )}
            {specialTradingHours?.length > 0 && (
              <SpecialOpenHours specialOpeningHours={specialTradingHours} />
            )}
          </DetailsWrapperInner>
        </DetailsWrapper>
        <MapWrapper>
          <Map
            location={location}
            selectedStore={store}
            mapStores={[store]}
            disableInfoWindow
          />
        </MapWrapper>
        <Back layout="mobile" as={Link} to={backUrl}>
          <Icon name={"arrowLeftSmall"} />
          <span>&nbsp;{additionalBackToStoresButtonText}</span>
        </Back>
      </Wrapper>
      <Sections page={{ content: footerContent }} />
    </>
  ),
)

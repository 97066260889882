import tw, { styled } from "twin.macro"

import { StyledContainer } from "../Styled/Container"
import { RichText } from "../RichText/RichText"

export const Wrapper = styled(StyledContainer)`
  ${tw`flex flex-wrap-reverse mb-2 md:mb-4 items-end pt-3-2 md:pt-8`}
`

export const DetailsWrapper = tw.div`
  w-full lg:w-1/3 flex flex-wrap items-start justify-center overflow-hidden lg:pr-2
`

export const DetailsWrapperInner = tw.div`
  w-full lg:mr-2 pb-3-2 border-b border-navy border-opacity-20
`

export const MapWrapper = tw.div`
  relative w-full lg:w-2/3 h-42 lg:h-64 lg:pl-6 mb-3-6
`

export const Back = styled.button`
  ${({ layout }) => {
    switch (layout) {
      case "desktop":
        return tw`hidden lg:flex`
      case "mobile":
        return tw`flex lg:hidden`
      default:
        return null
    }
  }}
  ${tw`w-full  flex-row items-center justify-start mb-1-6 lg:mb-3-2`}
  span {
    ${tw`font-bold text-14 leading-1.2 tracking-5 uppercase`}
  }
`

export const Divider = tw.hr``

export const SpecialDescription = styled(RichText)`
  ${tw`mt-1-6 px-2-4 py-1-6 bg-grey-light`}
`

export const Address = styled(RichText)`
  p {
    ${tw`text-grey`}
  }
`

export const DetailWrapper = tw.div`
  mb-3-2
`

export const Title = tw.h1`
  text-25 leading-1.4 tracking-10 uppercase font-bold my-3-2
`

export const SubTitle = tw.h3`
  text-14 leading-1.71 tracking-10 uppercase font-bold mb-0-8
`

export const Text = tw.p`
  text-14 leading-1.71 inline-block text-grey
`

import React from "react"
import { graphql } from "gatsby"

import { Store as Template } from "../components/Store/Store"

export const query = graphql`
  query TemplateStore($id: String!) {
    page: sanityStore(_id: { eq: $id }) {
      ...GatsbyStoreFragment
      footerContent: _rawFooterContent(resolveReferences: { maxDepth: 8 })
    }
    template: sanityTemplateStore {
      additionalAddressLabelText
      additionalPhoneLabelText
      additionalEmailLabelText
      additionalOpeningHoursLabelText
      additionalBackToStoresButtonText
      additionalSpecialOpeningHoursButtonText
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => <Template {...props} {...data} />
export default Component
